import React, {Component} from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import { withMobileDialog, withStyles, Card, CardMedia, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

import withRoot from '../components/withRoot';

import Layout from '../components/layout'
import SEO from '../components/seo'

const styles = {
  root: {
    // backgroundColor: '#aaa',
  },
  grid: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  element: {
    width: 180,
    height: 180,
    margin: '4px 4px 4px 4px',
    display: 'block',
    backgroundColor: 'white',
  },
  media: {
    width: 180,
    height: 180,
  }
};

class ResponsiveDialog extends Component {
  render() {
    const {photo, onClose, fullScreen} = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={photo !== undefined}
        onClose={onClose}
      >
        <DialogTitle>{photo ? photo.title : ''}</DialogTitle>
        <DialogContent>
          <img src={photo ? `https://storage.googleapis.com/pd-d.shinyuu.net/w/${photo.short_id}.jpg` : ''} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const MobileDialog = withMobileDialog({breakpoint: 'xs'})(ResponsiveDialog);

class IndexPage extends Component {
  constructor() {
    super()

    this.state = {
      photo: undefined,
      idx: 0,
    };
  }

  onOpenCard = (photo, idx) => {
    this.setState({
      photo,
      idx,
    });
  }

  onClose = () => {
    this.setState({
      photo: undefined,
      idx: 0,
    });
  }

  onKeyDown = (e, data) => {
    const {idx} = this.state;
    if (e.keyCode === 38 && idx > 0) {
      this.setState({
        idx: idx - 1,
        photo: data.allPhotodevPhoto.edges[idx - 1],
      });
    } else if (e.keyCode === 40 && idx < data.allPhotodevPhoto.edges.length - 1) {
      this.setState({
        idx: idx + 1,
        photo: data.allPhotodevPhoto.edges[idx + 1],
      })
    }
  }

  render() {
    const {classes} = this.props;
    return (<Layout>
      <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
      <StaticQuery
      query={graphql`
        query GalleryQuery {
          allPhotodevPhoto {
            edges {
              node {
                id
                ulid
                short_id
                title
              }
            }
          }
        }
      `}
      render={data =>
        <div className={classes.root} tabIndex="0">
          <div style={styles.grid}>
            {data.allPhotodevPhoto.edges.map(({node, index}) => {
              return <Card key={node.ulid} className={classes.element} onClick={() => this.onOpenCard(node, index)}>
                <CardMedia
                  className={classes.media}
                  image={`https://storage.googleapis.com/pd-d.shinyuu.net/p/${node.ulid}.jpg`}
                />
              </Card>
            })}
          </div>
          {this.state.photo && <MobileDialog
            photo={this.state.photo}
            onClose={this.onClose}
          />}
        </div>
      }
      />
    </Layout>);
  }
}

export default withRoot(withStyles(styles)(IndexPage));
